import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import disclaimerText from '../data/disclaimer'
import { section, wrapper, paragraph } from './terms-of-use.module.scss'

const TermsText = disclaimerText.split('\n').map((p, index) =>
  p ? (
    <p className={paragraph} key={index}>
      {p}
    </p>
  ) : null,
)

const TermsOfUse = () => {
  return (
    <Layout hideLogoText={false} showNavigation={true} footerVisible={false} >
      <Helmet>
        <title>Terms of use - Emso</title>
      </Helmet>
      <section className={section}>
        <div className={wrapper}>
          <div className="small-12 medium-12 large-10 xlarge-8">
            <h1>Terms of Use</h1>
            <div>{TermsText}</div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsOfUse
